








































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import LanguageTabs from "../LanguageTabs.vue";

const legalNamespace = namespace("Legal");

@Component({ components: { LanguageTabs } })
export default class LegalConfig extends Vue {
  isLoading = false;
  language = this.$i18n.locale;

  editorOption = {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["clean"],
        ["link", "image", "video"],
      ],
    },
  };

  @legalNamespace.Getter("legal")
  legalSetting!: { [lang: string]: string };

  @legalNamespace.Getter("generalConditions")
  generalConditions!: { [lang: string]: string };

  @legalNamespace.Getter("cookie")
  cookiePolicy!: { [lang: string]: string };

  @legalNamespace.Getter("privacy")
  privacyPolicy!: { [lang: string]: string };

  @legalNamespace.Action("fetchAll")
  fetchAll!: () => Promise<any>;

  @legalNamespace.Action("update")
  update!: (legal: any) => Promise<any>;

  async doSave() {
    this.isLoading = true;
    await this.update(this.legalSetting);
    this.isLoading = false;
  }

  async doFetch() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  created() {
    this.doFetch();
  }
}
